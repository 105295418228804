import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Request = () => {
  return (
    <Layout>
      <SEO description="Request veendor services through BuilderCover. We put builders and project owners in touch with the people needed to make construction projects successful."
        title="Request Construction Services" 
        keywords={[`construction services`, `building services`, `engineering`, `construction insurance`, `construction finance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    Request Services
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    One form to ensure your project is successful.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section container">
            <div class="cognito">
                <iframe title="service request form" src="https://services.cognitoforms.com/f/82h51wnA6EOj3eQRsqqVzg?id=1"></iframe>
                
            </div>
        </section>
    </Layout>
  )
}

export default Request

// `src/app` is not "special", it is re-exported by `src/pages/app.js`
// and contains all the clientside dynamic App pages that we dont want to be statically generated.
// `src/pages/app.js` skips the static generation process because of `gatsby-plugin-create-client-paths`
// configured in `gatsby-config.js`
